import React,{ useState } from "react";
import '../sass/main.scss';
import './statut.scss';

export const Accordion = ({ statut, valeur, content }) => {
	const [isActive, setActive ] = useState(false);
	return (

    <label className={`${isActive ? "statut__label statut__label__active" : "statut__label statut__label__unactive"}`} id="{ valeur }" >
    <input
      type="radio"
      name="status"
      className="status"
      value={ valeur }
      onClick={(e) => {
        e.target.checked = true;
        setActive(isActive);
        let parent = e.target.parentElement;
        e.target.setAttribute("checked","checked");
        if (parent.querySelector(".statut__descr").style.display !== "flex") {
          parent.querySelector(".statut__descr").style.display = "flex";
          parent.classList.add("statut__label__active"); 
          parent.classList.remove("statut__label__unactive"); 
        } else {
          parent.querySelector(".statut__descr").style.display = "none";
          parent.classList.remove("statut__label__active"); 
          parent.classList.add("statut__label__unactive"); 

        }
      }}
      onChange={e=>{
          e.target.parentElement.classList.add("checked");        
          e.target.parentElement.querySelector(".statut__descr").style.display = "flex"; 
          document.querySelectorAll("input[name='status']:not(:checked)").forEach(value=>{
              let parent = value.parentElement;
              value.removeAttribute("checked");
              parent.classList.remove("checked");
              parent.classList.remove("statut__label__active"); 
              parent.classList.add("statut__label__unactive"); 
              parent.querySelector(".statut__descr").style.display = "none";   

          });
          
          localStorage.setItem("status",valeur);
          localStorage.setItem("texteStatut",statut);
      }}
      //defaultChecked={localStorage.getItem("status") === statut}
      />
      <span>{statut}</span>
      <span className="statut__descr" style={{ display:"none" }}>{content}</span>
    </label>
    )
}