import React, { Component } from 'react';
import Infos from './Infos';
import Statut from './Statut';
import Result from './Result';
export class AutoEvaluation extends Component {
	state = {
		step: 1,
		niv: '',
		odo: '',
		typeOdo: '',
		marque: '',
		model: '',
		year: '',
		carrosseries: '',
		sousmodel: '',
		transmission: '',
		codepostal: '',
		status: '',
		accident: '',
		desrc: '',
		emailYN: '',
		email: ''
	}

	componentDidMount() {
	}    

	// Aller à l'étape précédente
	prevStep = () => {
		const { step } = this.state;
		this.setState({ step: step - 1 });
	}

	home = () =>{
		this.setState({
			step:1
		});
	}

	// Aller à l'étape suivante
	nextStep = () => {
		const { step } = this.state;
		this.setState({ step: step + 1 });
	}

	// Garder les changements
	handleChange = input => e => {
		this.setState({ [input]: e.target.value });
	}

	render = () => {
	  const { step } =this.state;
	  const { niv, odo, typeOdo, marques, model, year, carrosseries, sousmodel, transmission, codepostal, status, desrc, accident, emailYN, email } = this.state;
	  const values = { niv, odo, typeOdo, marques, model, 
		year, carrosseries, sousmodel, transmission, codepostal, status, 
		desrc, accident, emailYN, email }

		switch(step) {
		case 1:
			return (
				<Infos
					nextStep={ this.nextStep }
					handleChange={ this.handleChange }
					values={ values }
					step={step}
				/>
			)
		case 2:
			return (
				<Statut
					nextStep={ this.nextStep }
					prevStep={ this.prevStep }
					handleChange={ this.handleChange }
					values={ values }
					step={step}
				/>
			)
		case 3:
			return (
				<Result
					prevStep={ this.prevStep }
					handleChange={ this.handleChange }
					home =  {this.home}
					values={ values }
					step={step}
				/>
			)
		default:
		}  
	} 
} 
