import './sass/main.scss';
import { AutoEvaluation } from './components/AutoEvaluation';

export const App = () => {
	return (
		<div className="form">
			<AutoEvaluation />
		</div>
	);
}
export default App;
