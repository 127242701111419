import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import FR from "../i18n/fr/translation.json";
import EN from "../i18n/en/translation.json";
import Cookies from 'js-cookie';

const languageDetector = {
	type: 'languageDetector',
	async: true,
	detect: cb => cb('fr'),
	init: () => {},
	cacheUserLanguage: () => {},
};

i18next
	.use(languageDetector)
	.use(initReactI18next)
	.init({
		fallbackLng: 'fr',
		debug: false,
		resources: {
			en: {
				translation:EN,
			},
			fr: {
				translation:FR,
			},
		},
	});
const lang = Cookies.get("lang");
if ( lang !== "undefined" && lang !== undefined ) {
		if ( ["fr","en"].includes(lang) )
			i18next.changeLanguage(lang);
}