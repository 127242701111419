import React, { useEffect, useState } from "react";
import axios from "axios";
import { Form } from "../components/Form";
import { Button } from "../components/Button";
import "../sass/main.scss";
import "./infos.scss";
import { useTranslation } from 'react-i18next';
import { login } from "../services/initialise";

export const Infos = ({ step, nextStep, handleChange }) => {

	let local = "";
	const savedLang = localStorage.getItem("lang");
	if ( savedLang ) {
		local = "locale="+savedLang;
	} else {
		local = "locale=fr";
	}

	var emptyArray = [{ id: 0, name: process.env.REACT_APP_EMPTYVALUE}];
	const [  valueNiv, setValueNiv] = useState();
	const {t} = useTranslation();
	const [ marques, setMarques] = useState();
	const [ valueMarque, setValueMarque] = useState();
	const [ marqueError, setMarqueError] = useState("");
	const [ marqueLoading, setMarqueLoading] = useState(false);

	const [ model, setModel] = useState();
	const [ valueModels, setValueModels] = useState();
	const [ modelError, setModelError] = useState("");
	const [ modelLoading, setModelLoading] = useState(false);

	const [ year, setYear] = useState();
	const [ valueYears, setValueYears] = useState();
	const [ yearsError, setYearsError] = useState("");
	const [ yearsLoading, setYearsLoading] = useState(false);

	const [ trim, setTrim] = useState();
	const [ valueTrims, setValueTrims] = useState();
	const [ TrimsError, setTrimsError] = useState("");
	const [ TrimsLoading, setTrimsLoading] = useState(false);

	const [ carrosseries, setCarrosseries] = useState();
	const [ valueCarrosseries, setValueCarrosseries] = useState();
	const [ CarrosseriesError, setCarrosseriesError] = useState("");
	const [ CarrosseriesLoading, setCarrosseriesLoading] = useState(false);

	const [ transmission, setTransmission] = useState();
	const [ valueTransmissions, setValueTransmissions] = useState();
	const [ TransmissionsError, setTransmissionsError] = useState("");
	const [ TransmissionsLoading, setTransmissionsLoading] = useState(false);

	useEffect( () => {

		handleMarque().then( returnMarque => {
			let marque = localStorage.getItem("marque") ?? returnMarque;
			document.getElementById("idMarque").value = marque;

				handleModel(null, marque).then(async (returnModel) => {
					let model = localStorage.getItem("model") ?? returnModel;
					document.getElementById("idModel").value = model;

					handleYears(null,marque).then((returnYear) => {

						let year = localStorage.getItem("year") ?? returnYear;
						if (!isNumeric(year)) {
							year = null;
						}
						document.getElementById("idYear").value = year;
		
					handleCarrosseries(marque, model, year).then(() => {
						let elemCaro = document.getElementById("idCarrossery");
						if (elemCaro) 
							elemCaro.value = localStorage.getItem("carrosseries");
					});

					handleTrims(model, year).then(() => {
						let sousmodel = localStorage.getItem("sousmodel");
						document.getElementById("idTrim").value = sousmodel;
					});

				});
			});
		});
		
	},[]);

	const getMarque = () => {
		return axios.get(  
			process.env.REACT_APP_URIAPI+"/api/base/marques?"+local,  { headers: { Authorization: localStorage.getItem("token") } }
		).catch(error=>{
			if ( error.response ) {
				if ( error.response.status === 401 ) {
					return login(true).then(()=> {return getMarque();});
				}
			}
		});
	};

	const getYear = (marque,model) => {
		model = model??"";

		if ( marque.length === 0 )
			return null;

		return axios.get(
			process.env.REACT_APP_URIAPI+"/api/base/years/" + marque+"?model="+model, { headers: { Authorization: localStorage.getItem("token") } }
		).catch(error=>{
			if ( error.response ) {
				if ( error.response.status === 401 ) {
					return login(true).then(()=> {return getYear(marque,model);});
				}
			}
		});
	};

	const getTrims = (marque, model, year) => {
		return axios.get(
			process.env.REACT_APP_URIAPI + "/api/base/trim/" + marque + "/" + model + "/" + year, { headers: { Authorization: localStorage.getItem("token") } }
		).catch(error => {
			if (error.response) {
				if (error.response.status === 401) {
					return login(true).then(() => { return getTrims(marque,model, year); });
				}
			}
		});
	};

	const getCaro = (model, strget) => {

		strget = strget??"";

		if ( model.length === 0 )
			return null;

		return  axios.get(
			 process.env.REACT_APP_URIAPI + "/api/base/carrosseries/" + model + strget, { headers: { Authorization: localStorage.getItem("token") } }
		).catch(error=>{
			if ( error.response ) {
				if ( error.response.status === 401 ) {
					return login(true).then(() => { return getCaro(model, strget);});
				}
			}
		});

	};

	const getModel = (marque,year) => {
		year = year??"";

		if ( marque.length === 0 )
			return null;

		return axios.get(
			process.env.REACT_APP_URIAPI+"/api/base/models/" + marque+year, { headers: { Authorization: localStorage.getItem("token") } }
		).catch(error=>{
			if ( error.response ) {
				if ( error.response.status === 401 ) {
					return login(true).then(()=> {return getModel(marque,year);});
				}
			}
		});
	};

	const handleMarque = async () => {
		setMarqueLoading(true);
		if ( localStorage.getItem("listMarques") ) {
			const data = JSON.parse(localStorage.getItem("listMarques"));
			setMarques(data);
			const val = data[0].name;
			setValueMarque(val);
			if ( !localStorage.getItem("marque") ) 
				localStorage.setItem("marque",val);
			return val;
		} else {
			try {

				if ( !localStorage.getItem("token") ) {
					return await login(true).then(()=>{return handleMarque();});
				}

				const result = await getMarque();
				if ( !result.data.hasOwnProperty("message")  ) {
					setMarques(result.data);
					localStorage.setItem("listMarques",JSON.stringify(result.data));
					const val = result.data[0].name;
					setValueMarque(val);
					if ( !localStorage.getItem("marque") ) 
						localStorage.setItem("marque",val);

					return val;
				} else {
					setMarques(emptyArray);
					localStorage.removeItem("marque");
					return null;
				}
			} catch (err) {
				setMarqueError(err.message || "This is the wrong way!");
				localStorage.removeItem("marque");
			} finally {
				setMarqueLoading(false);
			}
		}

	};

	const handleModel = async (year =null, marque=null) => {
		setModelLoading(true);
		if ( localStorage.getItem("listModels") ) {
			const data = JSON.parse(localStorage.getItem("listModels"));
			setModel(data);
			const val = data[0].name;
			setValueModels(val);
			if ( !localStorage.getItem("model") ) 
				localStorage.setItem("model",val);

			return val;
		} else {
			let valMarque = null;
			let strYear = "";
			
			if(year!==null){
				strYear = "?year="+year+"&"+local;
			} else {
				strYear = "?"+local;
			}

			if (
				valMarque !== undefined
			) {
				try {
					const result = await getModel(marque,strYear);

					if ( !result.data.hasOwnProperty("message")  ) {
						setModel(result.data);
						const val = result.data[0].name;
						setValueModels(val);
						localStorage.setItem("listModels",JSON.stringify(result.data));
						localStorage.setItem("model",val);
					} else {
						setModel(emptyArray);
						localStorage.removeItem("model");
					}
					return document.querySelector("#idModel")?.value;
				} catch (err) {
					setModelError(err.message || "This is the wrong way!");
					localStorage.removeItem("model");
				} finally {
					setModelLoading(false);
				}
			}
		}
	};

	const handleYears = async (model=null, marque=null) => {
		setYearsLoading(true);
		if ( localStorage.getItem("listYears") ) {
			const data = JSON.parse(localStorage.getItem("listYears"));
			setYear(data);
			const val = data[0].name;
			setValueYears(val);
			if ( !localStorage.getItem("year") ) 
				localStorage.setItem("year",val);

			return val;
		} else {

			if (
				marque !== null
			) {
				try {
					const result = await getYear(marque,model);
					if ( !result.data.hasOwnProperty("message")  ) {
						setYear(result.data);
						localStorage.setItem("listYears",JSON.stringify(result.data));
						const val = result.data[0].name;
						setValueYears(val);
						document.querySelector("#idYear").value = val;
						localStorage.setItem("year",val);
						return val;
					} else {
						setYear(emptyArray);
						localStorage.removeItem("year");
					}
					let returnYear = document.querySelector("#idYear")?.value;
					
					return (returnYear === "process.env.REACT_APP_EMPTYVALUE")?null:returnYear;
				} catch (err) {
					setYearsError(err.message || "This is the wrong way!");
					localStorage.removeItem("year");
				} finally {
					setYearsLoading(false);
				}
			}
		}
	};

	const handleTrims = async (model=null, year=null) => {
		document.querySelector("#idTrim")?.setAttribute("disabled", "");
		setTrimsLoading(true);
		localStorage.removeItem("sousmodel");

		if ( localStorage.getItem("listTrims") ) {
			const data = JSON.parse(localStorage.getItem("listTrims"));
			setTrim(data);
			const val = data[0].name;
			setValueTrims(val);
			if ( !localStorage.getItem("sousmodel") ) 
				localStorage.setItem("sousmodel",val);

			return val;
		} else {
			let elemMarque = document.getElementById("idMarque");
			let elemModel = document.getElementById("idModel");
			let elemYear = document.getElementById("idYear");
			let valMarque = elemMarque?.value;
			let valModel = (model)??elemModel?.value;
			let valYear = (year)??elemYear?.value;

			if (
				(valMarque !== undefined && valMarque !== "" && valMarque !== null ) &&
				(valModel !== undefined && valModel !== "" && valModel !== null && valModel !== "process.env.REACT_APP_EMPTYVALUE" ) &&
				(valYear !== undefined && valYear !== "" && valYear !== null )
			) {
				try {
					const result = await getTrims(valMarque,valModel, valYear);
					if ( !result.data.hasOwnProperty("message")  ) {
						document.querySelector("#idTrim")?.removeAttribute("disabled");
						setTrim(result.data);
						localStorage.setItem("listTrims",JSON.stringify(result.data));
						const val = result.data[0].name;
						setValueTrims(val);
						localStorage.setItem("sousmodel",val);

					} else {
						setTrim(emptyArray);
						localStorage.removeItem("sousmodel");
						
					}
				} catch (err) {
					setTrimsError(err.message || "This is the wrong way!");
					
						localStorage.removeItem("sousmodel");
				} finally {
						
					setTrimsLoading(false);
				}
			}
		}
	};

	const handleCarrosseries = async (marque, model, year) => {
		document.querySelector("#idCarrossery")?.setAttribute("disabled", "");
		setTrimsLoading(true);
		localStorage.removeItem("carrosseries");
		if ( localStorage.getItem("listCaro") ) {
			const data = JSON.parse(localStorage.getItem("listCaro"));
			setCarrosseries(data);
			const val = data[0].name;
			setValueCarrosseries(val);
			if ( !localStorage.getItem("carrosseries") ) 
				localStorage.setItem("carrosseries",val);

			return val;
		} else {
			let strGet = "?year="+year+"&"+local+"&marque="+marque;
			try {
				let result = await getCaro(model, strGet);
				if ( !result.data.hasOwnProperty("message")  ) {
					document.querySelector("#idCarrossery")?.removeAttribute("disabled");
					setCarrosseries(result.data);
					const val = result.data[0].name;

					setValueCarrosseries(val);
					localStorage.setItem("listCaro",JSON.stringify(result.data));
					localStorage.setItem("carrosseries",val);
					
				} else {
					setCarrosseries(emptyArray);
					localStorage.removeItem("carrosseries");
				}
			} catch (err) {
				setTrimsError(err.message || "This is the wrong way!");
				localStorage.removeItem("carrosseries");
			} finally {
				setCarrosseriesLoading(false);
			}
		}
	};

	const setHandleMarque = async (e) => {
		e.preventDefault();
		const toDelete = [
			"listModels",
			"listYears",
			"listTrims",
			"listCaro",
			"carrosseries",
			"model",
			"year",
			"sousmodel",
		];
		toDelete.forEach((value) => localStorage.removeItem(value));
		setValueMarque(e.target.value);
		handleChange("marque");
		handleModel(null,e.target.value)
		.then((model)=>{
			handleYears(model,e.target.value).then(year=>{
				handleTrims(model,year);
				handleCarrosseries(e.target.value,model,year);
			});
		});
	};

	function isNumeric(n) {
		return !isNaN(parseFloat(n)) && isFinite(n);
	}

	const setHandleModels = async (e) => {
		e.preventDefault();
		localStorage.removeItem("listTrims");
		localStorage.removeItem("listCaro");
		localStorage.removeItem("carrosseries");
		localStorage.removeItem("sousmodel");
		localStorage.removeItem("listYears");
		setValueModels(e.target.value);
		handleChange("model");
		handleYears(e.target.value,localStorage.getItem("marque")).then(year=>{
			const yearValue = localStorage.getItem("year")??year;
			handleTrims(e.target.value,yearValue);
			handleCarrosseries(localStorage.getItem("marque"),e.target.value,yearValue);
		});

	};

	const setHandleYears = async (e) => {
		e.preventDefault();
		localStorage.removeItem("listTrims");
		localStorage.removeItem("listCaro");
		setValueYears(e.target.value);
		handleChange("year");
		handleTrims(localStorage.getItem("model"),e.target.value);
		handleCarrosseries(localStorage.getItem("marque"), localStorage.getItem("model"),e.target.value);
	};

	const setHandleTrims = async (e) => {
		e.preventDefault();
		setValueTrims(e.target.value);
		handleChange("sousmodel");
	};

	const setHandleCarrosseries = async  (e) => {
		e.preventDefault();
		setValueCarrosseries(e.target.value);
		handleChange("carrosseries");
	};
	
	const [isActive, setActive] = useState("false");

	const openModal = () => {
			setActive(!isActive);
	};

	const Continue = e => {
		e.preventDefault();
		const odo = localStorage.getItem("odo");
		const marque = localStorage.getItem("marque");
		const model = localStorage.getItem("model");
		const year = localStorage.getItem("year");
		let codepostal = localStorage.getItem("codepostal");
		let elemMarque = document.getElementById("idMarque");
		let elemModel = document.getElementById("idModel");
		let elemYear = document.getElementById("idYear");
		let elemOdo = document.getElementById("odo");
		let elemCodePostal = document.getElementById("codepostal");
		elemMarque.classList.remove("error");
		elemModel.classList.remove("error");
		elemYear.classList.remove("error");
		elemOdo.classList.remove("error");
		elemCodePostal.classList.remove("error");
		let returne = true;

		if ( !odo || !marque || !model || !year  ) {
			if ( !odo || odo === null ) {
				elemOdo.classList.add("error");
			}

			if (!marque) {
				elemMarque.classList.add("error");
			}

			if (!model) {
				elemModel.classList.add("error");
			}

			if (!year) {
				elemYear.classList.add("error");
			}

			returne = false;
		}
		if ( codepostal ) {
			codepostal = codepostal??"";
			let ca = new RegExp(/^[A-Z]\d[A-Z][ -]?\d[A-Z]\d$/i);
			if ( !ca.test(codepostal.toString().toUpperCase().trim()) ) {
				elemCodePostal.classList.add("error");
				returne = false;
			}
		}
		if ( !returne )
			return returne;
		nextStep();
	};

	const  setHandleNiv = async e =>  {
		let value = e.target.value;
		setValueNiv(value);
		[
			"idMarque",
			"idModel",
			"idYear",
		].forEach(value=>document.getElementById(value)?.removeAttribute("disabled"));

		if ( value.length >= 17 ) {
			localStorage.setItem("niv", e.target.value);
			await axios.get(
				process.env.REACT_APP_URIAPI+"/api/base/car/" + value, { headers: { Authorization: localStorage.getItem("token") } }
			).then(result =>{
				if (result.data && result.data.niv ) {
				
				[
					"listModels",
					"listYears",
					"listTrims",
					"listCaro",
				].forEach((value)=>localStorage.removeItem(value));
					result = result.data;
					let valMarque = result.marque,
					valModele = result.modele,
					valYear = result.year,
					valSous = result.sous_modele,
					elemMarque = document.getElementById("idMarque"),
					elemModel = document.getElementById("idModel"),
					elemYear = document.getElementById("idYear"),
					elemSous = document.getElementById("idTrim");
 
					if ( valModele.includes("/") ) {
						valModele = valModele.split("/")[0];
					}

					handleMarque().then(marque=>{
						elemMarque.value= valMarque;
						handleModel(null,valMarque).then(model=>{
							elemModel.value = valModele;
							handleYears(null,valMarque).then(year=>{
								elemYear.value = valYear;
								localStorage.setItem( "marque", valMarque );
								localStorage.setItem( "model", valModele);
								localStorage.setItem( "year", valYear );
							
								handleCarrosseries(marque, model, year);
								handleTrims().then(()=>{
									
									localStorage.setItem( "sousmodel", valSous );
									elemSous.value = valSous;
									
									if(valMarque) {
										setValueMarque(valMarque);
										elemMarque.setAttribute("disabled", "");
										
									}

									if(valModele) {
										setValueModels(valModele);
										elemModel.setAttribute("disabled", "");
									}

									if (valYear) {
										setValueYears(valYear);
										elemYear.setAttribute("disabled", "");
									}


									if (valSous) {
										setValueTrims(valSous);
									}

								});

							});
						});
					});
				}
			});

		} else {
			localStorage.removeItem("niv");
		}

	};

	return (
		<div>
			<Form>
				<div className='container__content'>
					<div className={`modal ${isActive ? "modal__none" : "modal"}`}>
					<div className="modal__wrapper">
					<div className="modal__header">
						<div className="modal__close"
						onClick={ openModal }></div>
					</div>
					<div className="modal__content">
						<h1 className="title">{t("modal.modaltitle")}</h1>
						<p>{t("modal.modaltext")}</p>
					</div>
					</div>
				</div>
				<h1 className="title">{t("global.title")} {step} {t("global.count")}</h1>
				<div className='container__grid-infos'>
					<div className='infos container__col form__default'>
					<label> 
					{t("info.labelniv")}
						<div className="infos__topspacing">
							<p className="reactLink" 
							onClick={ openModal }
							> 
								{t('info.linkniv')} 
							</p>
						</div>
					</label>
					<input
						type="text"
						id="niv"
						className="infos__text"
						placeholder={t('info.placeholderniv')}
						onChange={e=>{
							setHandleNiv(e);
						}}
						defaultValue={localStorage.getItem("niv")}
						/>
						</div>
						<div className='container__col'>
							<label className="étoile-obligatoire">
								{t("info.labelmarque")}
							</label>
							<select
								id="idMarque"
								onChange={e=>{
									setHandleMarque(e);
									setValueMarque(e.target.value);
									localStorage.setItem("marque",e.target.value);
								}}
							defaultValue={localStorage.getItem("marque")}
							>
								{marqueLoading}
								{marqueError}
								{marques?.map((marques) => (
									<option key={marques.id} value={marques.name} >{marques.name}</option>
								))}
							</select>
						</div>
						<div className='container__col'>
							<label className="étoile-obligatoire">
							{t("info.labelmodel")}
							</label>
							<select
							defaultValue={localStorage.getItem("model")}
								onChange={e=>{
									setHandleModels(e);
									setValueModels(e.target.value);
									localStorage.setItem("model",e.target.value);
								}}
								id="idModel"
							>
								{modelLoading}
								{modelError}
								{model?.map((models) => (
									<option key={models.id} value={models.name}>{models.name}</option>
								))}
							</select>
						</div>
						<div className='container__col'>
							<label className="étoile-obligatoire">{t("info.labelyear")}</label>
							<select
								defaultValue={localStorage.getItem("year")}
								onChange={e=>{
									setHandleYears(e);
									setValueYears(e.target.value);
									localStorage.setItem("year",e.target.value);
								}
								}
								id="idYear"
							>
								{yearsLoading}
								{yearsError}
								{year?.map((year) => (
									<option key={year.id} value={year.name}>{year.name}</option>
								))}
							</select>
						</div>
						<div className='container__col'>
							<label>{t("info.labeltrim")}</label>
							<select
								defaultValue={localStorage.getItem("sousmodel")}
								id="idTrim"
								onChange={e=>{
									setHandleTrims(e);
									setValueTrims(e.target.value);
									localStorage.setItem("sousmodel",e.target.value);
								}}
							>
								{TrimsLoading}
								{TrimsError}
								{
								trim?.map((trimvalue) => (
									<option key={trimvalue.id} value={trimvalue.name}>{trimvalue.name}</option>
								))}
							</select>
						</div>
						<div className='container__col'>
							<label>{t("info.labelcarrosseries")}</label>
							<select
								onChange={e=>{
									setHandleCarrosseries(e);
									setValueCarrosseries(e.target.value);
									localStorage.setItem("carrosseries",e.target.value);
								}}
								id="idCarrossery"
								defaultValue={localStorage.getItem("carrosseries")}
							>
								{CarrosseriesLoading}
								{CarrosseriesLoading}
								{carrosseries?.map((carrossery) => (
									<option key={carrossery.id} value={carrossery.name}>{carrossery.name}</option>
								))}
							</select>
						</div>
						<div className='container__col form__default'>
							<label className="étoile-obligatoire">{t("info.labelodo")}</label>
							<input
								type="number"
								min={0}
								max={10000000}
								step={100}
								id="odo"
								onChange={e=>{
									handleChange('odo');
									localStorage.setItem("odo",e.target.value);
								}}
								defaultValue={localStorage.getItem("odo")}
							/>
						</div>
						<div className='container__col form__default'>
							<label className="reference">{t("info.labelcodepostal")}</label>
							<input
								type="text"
								id="codepostal"
								required
								onChange={ e=>{
									handleChange('codepostal')
									localStorage.setItem("codepostal",e.target.value.toUpperCase().trim());
								}}
								defaultValue={localStorage.getItem("codepostal")?.toUpperCase().trim()}
							/>
						</div>
					</div>
					<p>1 - {t("info.infocodepostal")}</p>
				</div>
			</Form>
			<div className="containe">
				<div className="containe__position">
					<Button 
						onClick={ Continue }>
						{t("global.btncontinuer")}
					</Button>
				</div>
			</div>
		</div>
	)
}


export default Infos