import React, { useEffect } from "react";
import { Form } from "../components/Form";
import { Button } from "../components/Button";
import Cookies from "js-cookie";
import '../sass/main.scss';
import './result.scss';
import { useTranslation } from "react-i18next";
import * as axios from 'axios';
import { gtag_report_conversion } from "./googleAds.js";



export const Result = ({ step, prevStep, values, home }) => {
	const { niv } = values;
	const {t} = useTranslation();
	let min = 0, max = 0;
	const Send = e => {
		e.preventDefault();
		const email = localStorage.getItem("email");
		const elemEmail = document.getElementById("email");
		const emailRegex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');
		elemEmail.classList.remove("error");
		if ( !email ) {
			elemEmail.classList.add("error");
			return false;
		}

		if ( !emailRegex.test(email) ) {
			elemEmail.classList.add("error");
			return false;
		}
		
		let token = null;
		token = localStorage.getItem("token");
		
		if ( token === null ){
			token = Cookies.get("token");
		}

		axios.post(process.env.REACT_APP_URIAPI +"/api/autoevaluation/send",{
			email:localStorage.getItem("email"),
			id:localStorage.getItem("id"),
			// codepostal:localStorage.getItem("codepostal")
		},{
			headers: {
				Authorization: token
			}
		}).then(response=>{
			if ( response.data.status === "ok" ) {
				alert(t("result.emailmessage"));
				NewEval();
			} 
		});
	};

	useEffect(()=>{
		document.getElementById("root").scrollIntoView();

		let acc = localStorage.getItem('accident');
		let elemAlart = document.querySelector('#mess');
		if (acc === "Non"){
			if (elemAlart !== null) {
				elemAlart.classList.add("statut__none");
			}
		} 

		let token = null;
		token = localStorage.getItem("token");
		
		if ( token === null ){
			token = Cookies.get("token");
		} 

		var myHeaders = new Headers();
		myHeaders.append("Authorization", token);

		var formdata = new FormData();

		if ( niv ) {
			formdata.append("niv", localStorage.getItem("niv"));
		} else {
			formdata.append("year", localStorage.getItem("year")??1900);
			formdata.append("marque", localStorage.getItem("marque"));
			formdata.append("model", localStorage.getItem("model"));
			formdata.append("sousmodel", localStorage.getItem("sousmodel")??"");
		}
		
		formdata.append("odo", parseInt(localStorage.getItem("odo")));
		formdata.append("typeOdo", localStorage.getItem("typeOdo")??"km");
		formdata.append("status", localStorage.getItem("status"));
		formdata.append("accident", localStorage.getItem("accident"));
		formdata.append("transmission", localStorage.getItem("transmission")??"manuel");
		formdata.append("carrosserie", localStorage.getItem("carrosseries")??"");
		formdata.append("idMarchand", localStorage.getItem("idmarchand")??"200000");

		var requestOptions = {
			method: 'POST',
			headers: myHeaders,
			body: formdata,
		};
		localStorage.removeItem("min");
		localStorage.removeItem("max");
		fetch(process.env.REACT_APP_URIAPI +"/api/autoevaluation/autoeval", requestOptions)
			.then(response => response.json())
			.then(result => {
				if ( result.min && result.max ) {

					localStorage.setItem("id",result.id);
					document.querySelector(".min").innerHTML = parseInt(result.min).toLocaleString("fr-CA",{style:"currency",currency:"CAD"});
					document.querySelector(".max").innerHTML = parseInt(result.max).toLocaleString("fr-CA",{style:"currency",currency:"CAD"});
					//QRCode.toCanvas(document.querySelector("#qrCode"),process.env.REACT_APP_GVO+process.env.REACT_APP_GVO_LINK+localStorage.getItem("id"));
					document.getElementById("loading").style.display = "none";
					document.getElementById("btnEmail").style.display="block";
					document.getElementById("btnHome").style.display="block";
					document.getElementById("btnSrc").style.display="block";
					document.getElementById("final-result").style.display = "flex";
        }  else {
					document.getElementById("btnHome2").style.display="inline-flex";
					document.getElementById("loading").style.display = "none";
					document.getElementById("error-eval").style.display = "block";
				}

			})
			.catch(error => console.log('error', error));
		
	});

	const Preview = e => {
		e.preventDefault();
		prevStep();
	};
	
	const NewEval = e=>{
		[
			"niv",
			"marque",
			"year",
			"sousmodel",
			"model",
			"odo",
			"carrosseries",
			"accident",
			"status",
			"descr",
			"min",
			"max",
			"idResult",
			"email",
			"codepostal",
			"id",
			"listModels",
			"listYears",
			"listTrims",
			"listCaro",
		].forEach(elem=>localStorage.removeItem(elem));
		home();
	};

	const Src = e=>{
		e.preventDefault();

		window.location = "/";
	};
	function onClickSend(e) {
		gtag_report_conversion('undefined');
		Send(e);
	}
	return (
		<div>
			<h1 className="title">{t("global.title")} {step} {t("global.count")}</h1>
			<Form>
				<div id="loading" className="display"></div>
				<div id="error-eval"  style={{ display:"none" }}>
					<p className="title">{t("result.errortitle")}</p>
					<br/>
					<div className='container__content'>
						<p>{t("result.errormessage")}</p>
					</div>
				</div>
				<div id="final-result" style={{ display:"none" }} className='container__wrapper'>
					<div className='container__content'>
						<div className="result container__content">
							<div className="result__content result__border">
								<p className="result__tiny-text">{t("result.tradevalue")} : </p>
								<p className="result__price"> <span><span className="min"></span>&nbsp;CAN</span> - <span><span className="max"></span>&nbsp;CAN</span></p>
								<div className="result__line"></div>
								<div className="result__value"></div>
							</div>
							<p className="result__title" id="marque">{localStorage.getItem('marque')} {localStorage.getItem('model')} {localStorage.getItem('year')}</p>
							<p className="result__text" id="sousmodel">{localStorage.getItem('model')} {localStorage.getItem('sousmodel')}</p>
							<p className="result__text" id="odo">{localStorage.getItem('odo')} km</p>
						</div>
						<p className={localStorage.getItem('carrosseries')?"":"hide"}>{t("result.h1carrosserie")} : <span className="result__values">{localStorage.getItem('carrosseries')}</span></p>
						<p>{t("result.h1state")} : <span className="result__values">{localStorage.getItem('texteStatut')}</span></p>
						<p>{t("result.h1accident")} :  <span className="result__values">{localStorage.getItem('accident')}</span></p>
						<p className="statut__acc" id="mess"><span className="étoile">*</span>{t("status.spanaccident")}</p>
						<div className='container__col form__default'>
							<label>
								{t("info.labelemail")}
							</label>
							<input
								type="email"
								id="email"
								required
								onChange={ e=>{
									localStorage.setItem("email",e.target.value);
								}}
								defaultValue={localStorage.getItem("email")}
							/>
							<p className="statut__acc"><span className="étoile">*</span>{t("result.disclaimer")}</p>
						</div>
					</div>
				</div>
			</Form>
			<div className='containe'>
				<div className="containe__table">
					<table>
						<tr>
							<td>
								<Button 
									onClick={ Preview }>
										{t("global.btnprecedent")}
								</Button>
							</td>
							<td>
								<Button 
									style={{ 
										display:"none"
										}}
									id="btnEmail"
									onClick={onClickSend}>
										{t("global.email")}
								</Button>
							</td>
              				<td>
								<Button 
									style={{ 
										display:"none"
									}}
									id="btnHome2"
									onClick={ NewEval }>
										{t("global.new")}
								</Button>
							</td>
						</tr>
						<tr>
							<td>
								<Button 
									style={{ 
										display:"none"
									}}
									id="btnHome"
									onClick={ NewEval }>
										{t("global.new")}
								</Button>
							</td>
							<td>
								<Button 
									style={{ 
										display:"none"
										}}
									id="btnSrc"
									onClick={ Src }>
										{t("global.src")}
								</Button>
							</td>
						</tr>
					</table>
				</div>
			</div>
		</div>
	)
}

export default Result