import React from 'react';
import '../sass/main.scss';


export const Button = ({children, ...props}) => {
	return (
		<div
			type="submit"
			className="btn"
			{...props}
		>
			{children}
		</div>
	);
  };
	
  export default Button;
