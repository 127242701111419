
window.dataLayer = window.dataLayer || [];
function gtag(){window.dataLayer.push(arguments);}

export function gtag_report_conversion(url)  {
	var callback = function () { if (typeof(url) != 'undefined') { window.location = url; } };
	gtag('event', 'conversion', { 'send_to': 'AW-967032359/B_KhCKau2pABEKf8js0D', 'event_callback':
	callback });
	return false;
}
