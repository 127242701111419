import React,{useEffect} from "react";
import { Form } from "./Form";
import { Button } from "./Button";
import { Accordion } from "./Accordion";
import '../sass/main.scss';
import './statut.scss';
import { useTranslation } from "react-i18next";



export const Statut = ({ step, nextStep, prevStep, handleChange }) => {
	const {t} = useTranslation();    
	const data = [
		{
			statut: t("status.labelexcellent"),
			valeur: "excellent",
			content: t("status.descrexcellent")
		},
		{
			statut: t("status.labelverygood"),
			valeur:  "very_good",
			content: t("status.descrverygood")
		},
		{
			statut: t("status.labelgood"),
			valeur: "good",
			content: t("status.descrgood")
		},
		{
			statut: t("status.labelcorrect"),
			valeur: "correct",
			content: t("status.descrcorrect")
		},
	];

	useEffect(()=>{
		document.getElementById("root").scrollIntoView();

		if (!localStorage.getItem("status")) {
			localStorage.setItem("status", "correct");
		}

		document.querySelectorAll("input[name='status']:checked").forEach(elem=>{
			elem.parentElement.classList.add("checked");
		});

		if ( !localStorage.getItem("accident") )
			localStorage.setItem("accident","Non");
	});

	const Continue = e => {
		e.preventDefault();
		const status = document.querySelector('input[name="status"]:checked');
		const elemsStatus = document.querySelectorAll('.statut__label');
		elemsStatus.forEach((elem)=>{
			elem.classList.remove("error");
		});

		if (!status) {
			elemsStatus.forEach((elem)=>{
				elem.classList.add("error");
			});
			return false;
		}

		nextStep();
	};

	const Preview = e => {
		e.preventDefault();
		prevStep();
	};

	return (
		<div>
			<Form>
				<h1 className="title">{t("global.title")} {step} {t("global.count")}</h1>
				<div className="container__content">
					<p>{t("status.pstate")}</p>
					<p><span className="étoile">*</span>{t("status.avis")}</p>
					<div className="container__content">
						{data.map(({ statut, valeur, content}) => (
							<Accordion statut={statut} valeur={valeur} content={content} />
						))}
					</div>
					<div className='container__row-col'>
						<div className='container__content'>
							<p>{t("status.paccident")}</p>
							<div className='container__row-baseline'>
									<input
										type="radio"
										id="accident"
										name="accident"
										value="oui"
										onChange={e=>{
											handleChange('status')
											localStorage.setItem("accident","Oui");
										}}
										defaultChecked={localStorage.getItem("accident")=== "Oui"}
									/>
								<label>{t("status.labeloui")}</label>
								<input
									type="radio"
									id="accident"
									name="accident"
									value="non"
									onChange={e => {
										handleChange('status')
										localStorage.setItem("accident", "Non");
									}}
									defaultChecked={(localStorage.getItem("accident")) ? localStorage.getItem("accident") === "Non" : "Non"}
								/>
								<label>{t("status.labelnon")}</label>
							</div>
						</div>
					</div>
				</div>
			</Form>
			<div className='containe'>
				<div className="containe__position">
				<Button 
					onClick={ Preview }>
						{t("global.btnprecedent")}
				</Button>
				<Button 
					onClick={ Continue }>
						{t("global.evalemail")}
				</Button>
				</div>
			</div>
		</div>
	)
}

export default Statut
