import React from "react";
import "../sass/main.scss";


export const Form = ({children, ...props}) => {

	return (
		<form className='container' {...props} noValidate>
			{children}
		</form>
	);
};