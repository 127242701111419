import Cookies from "js-cookie";
[
	"niv",
	"marque",
	"year",
	"sousmodel",
	"model",
	"odo",
	"carrosseries",
	"accident",
	"status",
	"min",
	"max",
	"idResult",
	"email",
	"codepostal",
	"id",
	"listModels",
	"listYears",
	"listTrims",
	"listCaro",
	"descr"
].forEach(elem=>localStorage.removeItem(elem));

export async function login (force = false) {    

	const request = () => {
		const formdata = new FormData();
		formdata.append("token", process.env.REACT_APP_COMPTE);
		const requestOptions = {
			method: 'POST',
			body: formdata,
			redirect:"follow"
		};
		return fetch(process.env.REACT_APP_URIAPI.toString()+"/api/auth/login", 
		requestOptions)
		.then(reponse => reponse.json())
		.then(result => {
			localStorage.setItem("token","Bearer "+result.access_token);
			Cookies.set("bearer",result.access_token);
			return true;
		})
		.catch(error => {
			console.log('error',error)
			return false
		});
	}

	if ( force === true ) {
		return request();

	} 

	if (Cookies.get("bearer") === undefined || Cookies.get("bearer") ==="undefined" ) {

		if ( !localStorage.getItem("token") ) {
			return request();
		}
		return true;
	} else {
		localStorage.setItem("token","Bearer "+Cookies.get("bearer"));
		return true;
	}   
}

let url = new URL(window.location.href);
let firstColor = url.searchParams.get("first_color");
let secondColor = url.searchParams.get("second_color");
let font = url.searchParams.get("font");

let idmarchand = Cookies.get("idmarchand");

if ( idmarchand === undefined ) {
	idmarchand = 200000;
}

let style = document.documentElement.style;

if ( firstColor !== null ) {
	style.setProperty('--first-color', "#"+firstColor );
}

if ( secondColor!== null ) {
	style.setProperty('--second-color', "#"+secondColor );
}


if ( font !== null ) {
	style.setProperty('--font', font);
	font.replace(' ',"+");
	var link = document.createElement('link');
	link.setAttribute('rel', 'stylesheet');
	link.setAttribute('type', 'text/css');
	link.setAttribute('href', 'https://fonts.googleapis.com/css?family=' + font +':wght@100&display=swap');
	document.head.appendChild(link);

} else {
	style.setProperty('--font', ""); 
} 

if ( idmarchand!== null ) {
	localStorage.setItem("idmarchand",idmarchand);
} else {
	localStorage.setItem("idmarchand",process.env.REACT_APP_AUID);
}
	